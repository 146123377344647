<template>
  <div>
    <div class="header wrap">
      <div class="logo">
        <!-- <img src="../assets/img/logo.png" alt="" /> -->
        <div class="lotit">
        四川卿本家电子商务有限公司
        </div>
      </div>
      <ul class="navs">
        <li
          :class="{ active: $route.path == '/home' }"
          @click="$router.push('/home')"
        >
          首页
        </li>
        <li
          :class="{ active: $route.path == '/gywm' }"
          @click="$router.push('/gywm')"
        >
          关于我们
        </li>
        <!-- <li
          :class="{ active: $route.path == '/jjfn' }"
          @click="$router.push('/jjfn')"
        >
          解决方案
        </li>
        <li
          :class="{ active: $route.path == '/fwdt' }"
          @click="$router.push('/fwdt')"
        >
          服务大厅
        </li> -->
        <li
          :class="{ active: $route.path == '/qydt' }"
          @click="$router.push('/qydt')"
        >
          企业动态
        </li>
        <li
          :class="{ active: $route.path == '/lxwm' }"
          @click="$router.push('/lxwm')"
        >
          联系我们
        </li>
      </ul>
      <!-- <div class="search">
        <input type="text" placeholder="搜索" v-model="searchTxt" />
        <span @click="sendSearchTxt"
          ><img src="../assets/img/search.png" alt=""
        /></span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTxt: "",
    };
  },

  methods: {
    sendSearchTxt() {
      let keyword = this.searchTxt;
      this.$router.push(`/goods?keyword=${keyword}`);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    // width: 305px;
    width: 500px;
    img {
      display: inline-block;
      width: 100%; /* 图片的最大宽度是其父元素的100% */
      height: auto;
    }
    .lotit{
      font-size: 36px;
      font-weight: 600;
      // font-family: serif;
      font-family:cursive
    }
  }
  .navs {
    width: 500px;
    font-size: 14px;
    font-family: Arial;
    font-weight: 500;
    line-height: 23px;
    display: flex;
    justify-content: space-between;
    li {
      cursor: pointer;
      &.active {
        color: #0b5394;
      }
    }
  }
}
</style>
