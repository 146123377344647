<template>
  <div id="app">
    <!-- 顶部导航 -->
    <Header></Header>
    <!-- 路由组件 -->
    <router-view />
    <!-- 尾部组件 -->
    <Footer></Footer>
  </div>
</template>

<script>
// 导入组件

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  data() {
    return {};
  },

  components: {
    // 注册组件
    Header,
    Footer,
  },
};
</script>

<style lang="less"></style>
