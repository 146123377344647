<template>
  <div class="home">
    <div style="position: relative">
      <img
        src="@/assets/img/fwdt.png"
        alt=""
        style="display: inline-block; width: 100%; height: 300px"
      />
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 100;
          color: white;
          text-align: center;
        "
      >
        <div style="font-size: 40px; margin-bottom: 20px">服务大厅</div>
        <div style="font-size: 16px; margin-bottom: 0px">SERVICE HALL</div>
      </div>
    </div>
    <div
      style="
        width: 65vw;
        margin: 10px auto;
        position: relative;
        overflow: hidden;
      "
    >
      <div
        style="
          position: absolute;
          left: 50%;
          top: 30px;
          transform: translateX(-50%);
          font-size: 25px;
        "
      >
        {{ list[index] }}
      </div>
      <div style="margin-top: 80px" class="info">
        <div>联系人：&nbsp;九联云</div>
        <div>联系电话&nbsp;17345731063</div>
        <div>价格&nbsp;<span style="color: red">￥待议</span></div>
      </div>
      <div>
        <div style="font-weight: bold; font-size: 14px; margin: 25px 0 10px 0">
          {{ list[index] }}
        </div>
        <div style="font-size: 14px; line-height: 1.8">
          美术设计外包、技术开发服务型企业，汇聚了商业引擎专业的设计与开发人员。熟知各类符合客户产品需求的美术设计、手机应用开发、小程序开发、系统开发的技术实施及解决方案。北京九艺圆明数字科技有限公司，为您提供7X24小时日不落的技术支持和交付服务。
        </div>
      </div>
      <div
        style="
          margin-top: 20px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 228px;
        "
        class="tj"
      >
        <div style="margin-bottom: 10px" @click="frontClick">
          <img
            src="@/assets/img/上一个.png"
            alt=""
            style="
              display: inline-block;
              width: 14px;
              height: 14px;
              vertical-align: middle;
            "
          />
          &nbsp;前一个： &nbsp;{{ info.front }}
        </div>
        <div @click="backClick">
          <img
            src="@/assets/img/下一个.png"
            alt=""
            style="
              display: inline-block;
              width: 14px;
              height: 14px;
              vertical-align: middle;
            "
          />&nbsp;后一个： &nbsp;{{ info.back }}
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "fwdtDetail",
  data() {
    return {
      list: ["系统开发", "小程序开发", "手机应用开发"],
      info: {
        front: "无",
        back: "小程序开发",
      },
      index: 3,
    };
  },
  created() {
    console.log(this.$route.params);
    if (this.$route.params.item == "系统开发") {
      this.index = 0;
    } else if (this.$route.params.item == "小程序开发") {
      this.index = 1;
    } else if (this.$route.params.item == "手机应用开发") {
      this.index = 2;
    }
    if (!this.$route.params.item) {
      this.index = this.$store.state.index;
    }
    this.$store.commit("setindex", this.index);
    console.log(this.$store.state.index);
  },
  components: {},
  methods: {
    frontClick() {
      if (this.index <= 0) {
        return;
      }
      this.index--;
      this.$store.commit("setindex", this.index);
    },
    backClick() {
      if (this.index >= 2) {
        return;
      }
      this.index++;
      this.$store.commit("setindex", this.index);
    },
  },
  watch: {
    index(newV, oldV) {
      if (newV == 0) {
        this.info.front = "无";
        this.info.back = "小程序开发";
      }
      if (newV == 1) {
        console.log("llll");
        this.info.front = "系统开发";
        this.info.back = "手机应用开发";
      }
      if (newV == 2) {
        this.info.front = "小程序开发";
        this.info.back = "无";
      }
    },
  },
};
</script>
  <style scoped>
.info div {
  display: inline-block;
  margin-right: 40px;
  font-size: 14px;
}
.tj div:hover {
  color: lightskyblue;
  cursor: pointer;
}
</style>
  