
import { useListeners } from 'vue';

import { useListeners } from 'vue';

import { useListeners } from 'vue';

import { useListeners } from 'vue';

import Vue from 'vue/types/umd';
<template>
  <div>
    <div style="position: relative">
      <img
        src="../../../assets/img/qydt/qydt.png"
        alt=""
        style="display: inline-block; width: 100%; height: 300px"
      />
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 100;
          color: white;
          text-align: center;
        "
      >
        <div style="font-size: 40px; margin-bottom: 20px">企业动态</div>
        <div style="font-size: 16px; margin-bottom: 0px">NEWS</div>
      </div>
    </div>
    <div style="width: 70%; margin: 20px auto">
      <div
        style="
          width: 100%;
          text-align: center;
          font-size: 25px;
          margin-bottom: 30px;
        "
      >
        {{ content[qydtIndex].t }}
      </div>
      <div
        style="line-height: 1.7"
        id="content"
        v-html="content[qydtIndex].c"
      ></div>
      <div>
        <img
          :src="content[qydtIndex].img"
          alt=""
          style="width: 400px; height: 240px"
        />
      </div>
      <div
        style="
          margin-top: 20px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 228px;
        "
        class="tj"
      >
        <div style="margin-bottom: 10px; margin-top: 40px" @click="frontClick">
          <img
            src="@/assets/img/上一个.png"
            alt=""
            style="
              display: inline-block;
              width: 14px;
              height: 14px;
              vertical-align: middle;
            "
          />
          &nbsp;前一个： &nbsp;{{ content[qydtIndex].f }}
        </div>
        <div @click="backClick">
          <img
            src="@/assets/img/下一个.png"
            alt=""
            style="
              display: inline-block;
              width: 14px;
              height: 14px;
              vertical-align: middle;
            "
          />&nbsp;后一个： &nbsp;{{ content[qydtIndex].b }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        title: "",
        content: "",
        img: "",
      },

      content: [
        {
          t: "Al旗手李彦宏和他的新百度",
          f: "无",
          b: "业务应用场景受限，商汤科技能否把握发展机会?",
          img: require("../../../assets/img/qydt/qydt1.jpeg"),
          c:
            "如果没有陆奇百度依然会ALLIN AI，这事早在2012年就已经初露端倪，在那一年的百度年会上，李彦宏宣布百度将成立一个专注深度学习计划的研究院，第二年研究院如约而至，李彦宏亲任院长。再一年，吴恩达加盟百度担任首席科学家。从那时候开始一个全新的百度就已经悄悄起航了。" +
            "<br/>" +
            "吴恩达加盟百度两年后，在北京中国大饭店举办的2016百度世界大会上，拥有超大规模神经网络的百度大脑正式亮相，彼时百度大脑语音合成日请求量已达2.5亿，语音识别率达到97%。2018年7月4日百度AI开发者大会上，使用了多模态深度语义理解技术的百度大脑3.0发布，此时的百度大脑每日调用次数已达4000亿次。" +
            "<br/>" +
            "倪叔在之前的文章里曾经提到，李彦宏在彭博全球商业论坛的谈话中，我们应该抓住他所谈到的AI技术对B 端的影响。" +
            "<br/>" +
            "不管是传统互联网时代还是移动互联网时代，当所有人都把注意力放在对C端上的时候，我们更应该关注的是AI端对B端的影响，这将对整个社会产生更深远的影响。当大部分公司都把注意力放在流量生意上的时候，远在2013年的时候，百度就已经每年100亿砸在了AI上。" +
            "<br/>" +
            "那时候陆奇和吴恩达都还没有加盟百度。" +
            "<br/>" +
            "1、百度的底气" +
            "<br/>" +
            "相比较于阿里和腾讯，百度有些“先天不足”。通过交易可以建立一个庞大的以支付为核心的交易帐号体系，而社交关系链的迁移成本则像一个黑洞一样网罗住了10亿网民，在BAT三家的业务里搜索天然无法构建拥有超强粘性的帐号体系。于是在移动互联网的大潮下百度毫不意外的掉队了。父子以为百度这种先天不足，反而倒逼了百度必须ALL IN 技术。" +
            "<br/>" +
            "移动互联网的十年大潮里，小米、京东和今日头条等公司不断崛起，不断向互联网第三极发起挑战，市场被阿里、腾讯拉开了距离的百度成了反面教材，唱衰百度成了一种政治正确，似乎唱衰了百度后自己就能成为第三极。以致于人们忽略了一个显在的事实，抛开互联网公司的市值排位不谈，百度一直都是家非常赚钱且越来越能赚钱的公司。" +
            "<br/>" +
            "根据2018年百度第三季度的财报显示，百度在线营销收入同比上升18%，占总营收的比例却下降了6个百分点，百度营销之外的业务也开始发力创收。" +
            "互联网第三极的位置看似险像环生实则稳如泰山。" +
            "<br/>" +
            "9月27日的彭博全球商业论坛上，国际货币基金组织总裁拉加德提到，百度在全球四万名员工中有一万五千名计算机科学家，2017年福布斯评选了20位推动中国人工智能改革的科技领导者其中有10位来自于百度。百度的技术之所以和阿里的运营、腾讯的产品齐名在外，离不开对技术的持续投入和对未来的坚定信心。" +
            "<br/>" +
            "2017年10月18日，李彦宏在《华尔街日报》主办的WSJ.D Live大会上表示，百度每年会把15%的营收用于研发，大约为100亿人民币。从启动深度学习研究工作、成立深度研究院并亲任院长开始，用一年100亿真金白银的投入，站在2018年百度世界大会上，李彦宏终于有底气喊出了那句：“Yes，AI Do”。",
        },

        {
          t: "业务应用场景受限，商汤科技能否把握发展机会?",
          f: "Al旗手李彦宏和他的新百度",
          b: "五国议会联合要求扎克伯格出席听证会但全遭拒绝?",
          img: require("../../../assets/img/qydt/qydt2.png"),
          c:
            "近年，人工智能迎来了商业化的浪潮。不少企业集中于一个或多个领域，做出了一些喜人的成绩。以主攻计算机视觉的商汤科技为例，4年来不断拿下多轮融资，在人脸识别、智能安防多个应用场景也有着相当的知名度。" +
            "<br/>" +
            "但是商汤科技的地位并不稳固，行业内还有众多的竞争对手，在争夺场景的过程中，商汤科技也并不轻松。面对传统企业的坚固壁垒和BAT的强势覆盖，商汤等AI新秀想要抢占场景陷入了怎样的困境？如果要在AI时代还能够占有一席之地，商汤科技应该如何去抓住机遇、迎接挑战？" +
            "<br/>" +
            "商汤科技飞速发展 人工智能行业战况焦灼" +
            "<br/>" +
            "<br/>" +
            "2014年前后，众多人工智能方向的企业诞生，这其中就包括了专注于计算机视觉领域的商汤科技。单看商汤科技自身，其发展和融资的速度都非常可观。其业务方位早已扩大到人脸识别、智能安防、语音文字识别、深度学习等各个方面。" +
            "<br/>" +
            "今年，商汤科技相继获得阿里领投6亿C轮融资和软银中国10亿美元投资，估值超60亿美元，可谓是超级独角兽了。乃至商汤自己也开始对外投资，有意培养出一些小独角兽，完善自身的AI布局。" +
            "<br/>" +
            "不过融资力度如此之大，并非商汤科技一家所独有。但凡是做AI，谋求的是未来，烧掉的是巨款，AI企业具有高估值本是常态。与商汤科技类似的企业还有很多，旷视科技、云从科技和依图科技都是这一类的企业，它们也获得了不少的资本加持，其中旷视科技也加入了阿里系的阵营。" +
            "<br/>" +
            "除了各AI企业的兴起和竞争，BAT也纷纷进入这一领域。腾讯优图实验室的核心技术就是视觉识别和图像处理，百度大脑也建设了完备的图像和视频识别技术，并且对外开放。阿里同样也在攻坚人脸识别，而且旗下投资的AI企业非常多，商汤科技只能算是其中一家。如果是在计算机视觉以外的领域，BAT的覆盖面和技术实力都更加强大。" +
            "<br/>" +
            "因此商汤科技虽然成绩亮眼，却也处于焦灼的战事中。如果不能将技术广泛落地，随时都有被竞争者超越的风险。" +
            "<br/>" +
            "<br/>" +
            "应用场景并不稳固 传统企业和BAT地位强势" +
            "<br/>" +
            "目前商汤科技的落地集中于手机厂商、车企和少数公共场景等方面，包括向一些手机品牌提供人脸识别、分类相册等智能功能，和丰田一起研发自动驾驶技术，和少数地方的公安部门合作等等。但是其覆盖范围并不是很广泛，因为在各个领域都还有竞争对手的身影，有的本身就具有优势。商汤科技在争夺场景的过程中存在者较多的困难。" +
            "<br/>" +
            "（1）传统场景不易渗透 传统企业有其自身布局" +
            "<br/>" +
            "传统场景有传统企业，它们的技术本身也具有竞争力。以安防为例，商汤科技想要渗透进入，并非易事。传统企业本身做得并不差，如海康威视在权威榜单中位列全球第一，大华的产品也走向了世界。面对安全领域的应用选择，客户对这些传统且有品牌公信力的企业更为放心。" +
            "<br/>" +
            "同时传统安防巨头的资源布局也不是从AI技术切入到安防业务里来的商汤科技所能超越的。传统厂商同时也在寻求人工智能的升级，所以到最后商汤科技能不能从传统厂商手里抢到场景落地的机会还是很难说的。" +
            "<br/>" +
            "（2）新兴场景虽有机会 但BAT更具影响力" +
            "<br/>" +
            "除去传统场景之外，互联网、智能设备上的场景还是更容易进入一些。但是这些领域同样也是BAT虎视眈眈的市场。首先互联网上大量场景最终都是在BAT的控制之下，在他们自身都在发展AI业务的时候，这些场景优先会给自己的应用提供便利。例如腾讯具备很多场景，这些场景就会更优先满足优图还是AI Lab。" +
            "<br/>" +
            "在加上其他尚未被控制的互联网应用，可能有不少是基于阿里云或腾讯云的服务。如果需要的话，其场景也更倾向于使用阿里或腾讯提供的AI能力。商汤科技如果不借助阿里的力量，便很难从中找到更多的落地空间。" +
            "<br/>" +
            "（3）场景争夺中优势不明显 价格战会产生重要影响" +
            "<br/>" +
            "传统企业和BAT控制力没那么强的场景，才是商汤科技最有机可乘的部分。但是去争夺这些场景的过程中，还要担心或许会输给价格战。巨头们的AI业务作为战略的一部分，并不用在乎一时的烧钱补贴，而AI企业则是把其当做主营业务在做，两者的地位不一样，在竞争中的相对心态和资源也不同。并且在一些政府场景中，商汤的外资成分也是一道障碍，一些传统企业更有可能获得to G的订单。" +
            "<br/>" +
            "<br/>" +
            "把握未来发展机会 商汤需要发挥自身特长" +
            "<br/>" +
            "所以尽管商汤科技的技术也获得过业内的认同，但在场景的争夺上并不具有太大的优势，接下来商汤科技应该如何发挥自身的特长，保证不在AI竞争中被对手和巨头甩在身后呢？" +
            "<br/>" +
            "（1）把握阿里的资源 获得更多落地的机会" +
            "<br/>" +
            "作为阿里和软银投资的独角兽，商汤科技还是得到了阿里的认可，阿里系场景会是商汤科技寻求落地的机会，其实包括很多以前不被人注意的场景。以优酷为例，视频软件应用人脸识别功能测距帮助护眼，通过这类应用，当你的眼睛离屏幕过近，屏幕就会自动模糊。这种应用可能会是一个方向，目前腾讯视频就已经在进行探索了。" +
            "<br/>" +
            "当然这只是一个例子，人脸识别在阅读、视频、游戏等领域的场景还有很多，商汤科技如果可以抓住机会，还是可以通过阿里系的资源获得较多的应用场景的。" +
            "<br/>" +
            "（2）全行业趋向整合 用特长占据一席之地" +
            "<br/>" +
            "目前，众多AI企业的重复研发和内耗竞争并不利于AI事业的发展，要提高效率，配合政策对AI产业的支持导向，行业最终还是要走向有序和分工。在这一过程中，在具体领域有突出的技术优势的企业，更容易被认为是产业链上不可或缺的一部分。" +
            "<br/>" +
            "因此，在计算机视觉尤其是人脸识别等领域，商汤科技发挥自身的特有优势，或许有希望在未来占据一席之地。为了促进这一幕的实现，商汤也需要推动行业向有序化和分工化前进。" +
            "<br/>" +
            "总的来说，商汤科技在AI行业仍是一家明星企业，在计算机视觉和深度学习方面有其自身的野心和布局。但是落地场景的竞争并非易事，商汤科技在诸多方面可能难以抵挡对手的进攻。为了保证商汤在未来的AI产业里拥有一席之地，商汤科技还需要把握住阿里的资源，发挥自身特长，从而让自身拥有一定的个护城河。至于这一目标到底能否实现，还需观察今后行业的发展变化。",
        },
        {
          t: "五国议会联合要求扎克伯格出席听证会但全遭拒绝",
          f: "业务应用场景受限，商汤科技能否把握发展机会?",
          b: "天猫双11十年如何下一个开始",
          img: require("../../../assets/img/qydt/qydt3.jpeg"),
          c:
            "A5创业网(公众号：iadmin5)11月8日消息：此前因受到Facebook上假新闻问题有影响，多国议会集体讨伐扎克伯格要求出席本国议会听证会。但据财富杂志报道，这些国家的请求遭到扎克伯格拒绝。" +
            "<br/>" +
            "<br/>" +
            "据了解，扎克伯格拒绝了由英国议会领导并由加拿大、澳大利亚、爱尔兰和阿根廷议会共同组成的“国际大委员会”。他们原定于11月27日举行听证会。不过Facebook方面对加拿大议会和英国议会回复的邮件中显示，扎克伯格“img1不可能”出席所有的这样的活动。" +
            "<br/>" +
            "早在2016年，Facebook在美国大选中成为了假新闻的传播者，许多国家要求Facebook方面尽快出台措施应对假新闻问题。此前扎克伯格已经在美国和欧盟的听证会上作证，但这对于政府来讲还不够。" +
            "<br/>" +
            "由英国主导的“国际大委员会”收到来自Facebook方面回应后表示：“Facebook不屑一顾的反应让我们感到非常失望。五国议会现在代表这些国家的1.7亿用户呼吁你们去做正确的事情。”",
        },
        {
          t: "天猫双11十年如何下一个开始",
          f: "五国议会联合要求扎克伯格出席听证会但全遭拒绝?",
          b: "苹果有望在2年内完成Mac自家芯片供给，这三种情况最有可能发生",
          img: require("../../../assets/img/qydt/qydt4.jpeg"),
          c:
            "DoNews11月11日消息（记者 翟继茹）2018年11月11日凌晨1点，天猫双十一销量飙升突破700亿元，为第十年天猫双十一整体战绩开了一个好头儿。站在“十年”的节点上，从过去到现在，天猫双11从一场生存之战成为了阿里巴巴尝试新商业模式的试验场；从现在到未来，为了不断的增量，越来越不容易“讨好”的用户体验，天猫又将如何继续走下去？" +
            "<br/>" +
            "从“生存下去”到展示“阿里力量”" +
            "<br/>" +
            "阿里巴巴CEO张勇在第十年天猫双11开幕仪式上感慨地说，“十年前我们是为了求生存，为了活下去，造了个“双十一”的节，没想到一下子搞大了……”。" +
            "<br/>" +
            "张勇的说法并不夸张，天猫双11的诞生就是为了通过一场疯狂的折扣活动让天猫（彼时还叫淘宝商城）能够活下去。2008年4月，致力于B2C业务的淘宝商城立项。但当时， B2B的阿里巴巴和C2C的淘宝网才是阿里集团的“C位”，淘宝商城业务一直低迷，团队中离职不断，没有稳定的负责人。直到2009年3月，加入阿里不足两年的张勇接手淘宝商城时，团队人数不过20人。为了扭转颓势，张勇决定在国庆节之后、圣诞节之前进行一次促销活动，日期便锁定在了“11月11日”——当时大学校园里极其流行的“光棍节”——单身的人闲着也是闲着，买东西吧。" +
            "<br/>" +
            "出乎意料的，第一年双十一全天的销售额就达到了5200万元，约是平日的10倍。虽然这个数字与淘宝网2000亿的全年交易额无法相提并论，但大家却看到了这个节日的爆发力。" +
            "<br/>" +
            "更为重要的是，作为B2C业务代表的淘宝商城坚定了这个方向。事实证明，从2010年开始，B2C开始井喷式发展，京东和凡客当年的增长率一度达到300%和400%，而淘宝商城的全年交易额更是达到300亿，是京东的三倍，领跑整个B2C行业。在阿里巴巴内部，B2C业务终于不再被边缘化，逐渐走到了中心，而这一切不得不归功于第一个双十一。走过了生存之战的艰辛后，此后天猫双十一的定位越来越像一场场商业模式创新的实验。" +
            "<br/>" +
            "从2012年到2017年，191亿元、350亿元、571亿元、912亿元、1207亿元、1682亿元，各种新的模式创新就在这不断攀升的交易额所带来的一些“麻烦”中。" +
            "<br/>" +
            "2013年，菜鸟网络成立，最初的愿景就是解决双十一物流爆仓的问题，它的目标是协同打造一个开放的社会化物流大平台，让用户快速收快递，让商家最高程度降低物流成本。而如今，菜鸟已经成为阿里巴巴全球化的三大基础设施中最重要的一环。" +
            "<br/>" +
            "2014年，天猫双11开始统计移动端销售额，打开了全新的“AII IN 无线”时代。2015年，第一届“猫晚”亮相，为了双十一倒计时，各品牌的代言人使劲浑身解数来拉广告，虽然被网友调侃为“电视购物”，但毫无疑问，天猫开始切入“内容”营销，明星效应确实达到了“带货”的效果。也正是在这一年，天猫双11中正式强调全球化。2017年，天猫双11提出“新零售大阅兵”的口号，线下线上融合成为一场汹涌的趋势，开始席卷各行各业。在今年的双11中，阿里巴巴宣布阿里各生态经济体AII IN加入，开始了一场前所未有的“协同”进攻。" +
            "<br/>" +
            "至此，张勇也正式为如今的天猫双11做了新的定义——天猫双十一已经成为阿里展示整个生态力量的舞台，这个时间点，围绕商业、金融、云计算和物流的基础设施建设将向全球展示新成果。" +
            "<br/>" +
            "在此定位上，天猫总裁靖捷也不止一次公开表示，天猫双11将越来月淡化对最终一个销售额“数字”的追求。但是，成为“舞台”的“双十一”不代表天猫或者阿里真的会放弃对“量”的追求；相反，它的野心将更大。" +
            "<br/>" +
            "下一个“增量”在哪里" +
            "<br/>" +
            "其实，过去十年，每逢变化来临，阿里巴巴的电子商务核心业务都能收割国内的绝对增量市场，而这些动作都会反映在每年的天猫双十一上。除了适应了B2C的大势外，最明显的例子还有2014年阿里提出“All in 无线”的战略，帮助天猫在PC转移动的时代占据了充分优势。" +
            "<br/>" +
            "事实上，阿里内部员工告诉DoNews其实早在2010年阿里高层就开始关注智能机的发展和其将带来的生活方式的改变。2013年，张勇正式召集阿里集团高层讨论电商的无线战略，2014年，“All in 无线”战略正式实施。这一年，天猫双十一也开始统计移动端销售情况。当年，其移动交易额达243.3亿元，相较2013年移动端113亿的交易额增长了一倍，约占全网销售额的42.6%，且这一比例逐年增长，直至2017年提升至 91.2%。" +
            "<br/>" +
            "而2017年，阿里巴巴全年财报显示，其电商月活跃用户5.07亿，根据中国互联网络信息中心数据显示，截至2017年12月,我国手机网民规模达7.53亿。这说明，在移动端收割增量的时代已经结束，不仅在移动端，整体线上市场的流量都开始令人“焦虑”。所以，这一年双十一天猫举起“新零售”的大旗，希望通过线上线下整合来获取新的增量。" +
            "<br/>" +
            "关于增量的尝试，早在2014年左右张勇曾提出过三点“突破”——一是向国外市场进军，包括国外产品引进满足中产用户需求和国内产品输送到“华人区”；二是进军农村市场；三是深耕现有用户，当时便提出了根据阿里旗下内容平台如优酷、虾米、UC和高德等用户数据，深挖用户个人偏好。而这三点，在天猫国际上线、不断加码农村淘宝、各生态经济体ALL IN协同等动作中都可见一斑。" +
            "<br/>" +
            "但在农村等低线市场方面，天猫和淘宝或许都没有想到拼多多的快速进击。所以在今年双十一的开幕式上，天猫总裁靖捷明确提出了未来新的增量来源——新需求、新体验和新市场，其中新市场指的就是农村等低线城市。" +
            "<br/>" +
            "靖捷告诉DoNews, 今年双十一将有效覆盖全国29个省800县的超1亿人农村消费市场，这是往年都没有达到的规模。这也意味着，未来渗透农村等低线市场将是天猫又一新任务。" +
            "<br/>" +
            "如何让“买卖”关系更天然" +
            "<br/>" +
            "2012年1月11日，淘宝商城正式更名“天猫”。同年，天猫双十一正式变成“购物狂欢节”，不再是大家印象中的“光棍节大促”。从那一年开始，“折扣”、“大促”这样的字眼逐渐被天猫双11淡化，红包、订金等各类营销模式成为吸引用户消费的新方式。阿里希望用更自然的方式让用户无时无刻、更加自然的“买卖”，或者激起购买的欲望。" +
            "<br/>" +
            "在今年双11中，游戏和社交成为中心词。一场全民集能量从狂热到疲惫，上海白领小露不仅每天要发布点赞的邀请、密切关注自己的战队的能量分是否被对手超越了，还要接受朋友们不断的点赞邀约。从微信把集赞内容复制到天猫淘宝，再从天猫淘宝把集赞粘贴到微信群中，是她最“厌烦”的操作。于是，为了简化过程，她和许多朋友终于互相加了淘友。但是，小露也表示她和身边的朋友已经感觉“累觉不爱”，为了十几块钱，甚至是几块钱耗费了太多精力和时间。" +
            "<br/>" +
            "更为明显希望抓住“社交”这一利器的表现还在“小黑裙”玩法，淘气值600分以上的女性用户可以建立一个5-7人的小群，分享商品和店铺，回报是相应的红包和大额拼单券。相比较pk集能量，“小黑裙”活动可以延续至双11之后，而这一玩法的意图就是将“群聊”的场景通过购物复制到淘宝中。" +
            "<br/>" +
            "这样的方式，一方面功不可没成，“PK集能量”和“小黑裙”都调动起了用户的积极性，并让一部分人开通了此前完全没有关注过的“淘友”，为阿里的社交梦奠定了第一步；另一方面，它也引来了争议，有很多人像小露一样喊出了“累觉不爱”的感慨，“歧视消费者时间”、“浪费用户时间”、“价格歧视”、“羊群效应”等吐槽也接连不断。" +
            "<br/>" +
            "一位互联网资深从业者认为，“或许，天猫下一步应该学着如何让‘关系‘建立得更加温和，无论从内容、社交、还是游戏的角度，用户体验能够始终放在第一位。“" +
            "<br/>" +
            "最后，今年双十一，或许有的朋友会出现这样的情况：你并没有什么特别需要买的东西，可是刷着刷着剧就蹦出了商品推荐、发给你购物红包，在蚂蚁庄园浇着树喂着鸡就又拿到了购物红包，饿了点个外卖还会有红包，周末出门狂个商场还是会有各式各样的天猫双11红包拿，最后回到家，天猫精灵竟然也会一遍一遍反复提醒你——“双11要到了，可以设置红包领取提醒哦！”" +
            "<br/>" +
            "虽然可能已经有点“烦”，但你还是会窃喜既然拿了这么多红包，干脆把购物车装一装，清一清吧……不管你感觉如何，双十一已经无处不在。",
        },
        {
          t: "苹果有望在2年内完成Mac自家芯片供给，这三种情况最有可能发生",
          f: "天猫双11十年如何下一个开始",
          b: "谷歌员工罢工新进展: CEO发信回应大规模抗议?",
          img: require("../../../assets/img/qydt/qydt5.jpeg"),
          c:
            "编者注：苹果放弃Intel处理器的传闻已久，更曾传言指最快将在2020年实现这一目标。今年苹果推出的A12X处理器性能十分强大，甚至超过了Intel的酷睿i5。于是乎，苹果何时在Mac产品线上放弃Intel处理器，完全采用自家芯片也成为热议话题。那么苹果是如何构思的？它在准备些什么呢？本文作者Jeremy Horwitz，专注报道苹果、人工智能和相关科技新闻。" +
            "<br/>" +
            "如果你从早期的iPad时代就开始关注苹果，你可能已经注意到该公司越来越多的设备开始使用苹果自己研发的芯片。尽管与苹果芯片相比，英特尔芯片展示出更好的性能，但Mac电脑似乎注定了最终会从Intel芯片转移到自家的芯片上。" +
            "<br/>" +
            "上周，苹果推出了A12X处理器。搭载这款苹果最新且性能最好的A12X处理器的iPad Pro终于能够与基于Intel处理器的MacBook Pro相媲美。Mac向使用苹果自主研发的处理器的过渡即将来临，现在，问题就是“如何实现？”以及“什么时候实现？”" +
            "<br/>" +
            "在花了数年时间撰写关于苹果的文章后，我认为Mac转向苹果自开发芯片的情况可能会在未来两年内发生，有三种可能的情况。我把这三种情况分别称为“混合型”、“A系列”和“Z系列”。但是，在讨论之前，我们有必要先提供一些相关的背景信息。" +
            "<br/>" +
            "上一次重大转变：从PowerPC到Intel芯片" +
            "<br/>" +
            "在2005年之前，苹果还一直在花费大量的财力和精力试图让消费者相信，AIM开发的PowerPC处理器在Mac电脑上的表现优于英特尔的奔腾处理器。苹果利用各种形式的Mac广告努力吸引更多的Mac用户，但除了苹果的铁杆粉丝外，所有人都知道在这场战争中，Mac的失败大局已定。尽管首席设计官Jony Ive的设计越来越与众不同，但由于Mac产品过于昂贵，它们仍停留在2%左右的市场份额。搭载英特尔芯片的Windows电脑继续主导着个人电脑市场。" +
            "<br/>" +
            "在与英特尔竞争多年之后，苹果判定PowerPC芯片是一条死胡同。因此在2005年，该公司宣布将Mac电脑的芯片换成英特尔的酷睿系列芯片。这是一项非常严肃、重大的决定，因为需要全新的Mac硬件，Mac OS开发人员不得不重新编码从PPC到Intel的所有应用程序，最终确保混合的“通用二进制”可以在两种类型的机器上运行。" +
            "<br/>" +
            "由于更换了芯片，苹果获得了市场优势，Mac电脑的性能也可以直接与Windows电脑相媲美。不仅如此，它还能提供Boot Camp，用户使用这种工具可以在Mac电脑运行Windows。由于使用了英特尔的芯片，苹果产品能够吸引大量PC用户，Mac的销量和市场份额最终比PowerPC时代增长了两倍多。不管这是由于芯片的变化、iPod的“光环效应”，还是两者的结合，抑或是其他因素，苹果从Windows个人电脑领域赢得了大量客户，这在很大程度上要归功于它将芯片从PowerPC转向英特尔。",
        },
        {
          t: "谷歌员工罢工新进展: CEO发信回应大规模抗议",
          f: "苹果有望在2年内完成Mac自家芯片供给，这三种情况最有可能发生",
          b: "大河奔流二十载腾讯再越分水岭",
          img: require("../../../assets/img/qydt/qydt6.jpeg"),
          c:
            "A5创业网(公众号：iadmin5)11月9日消息：针对蔓延的抗议运动，谷歌首席执行官 Sundar Pichai刚刚向员工发送了一份备忘录，详细介绍了该公司的不当性行为政策的变化—— 其中包括将对个人性骚扰和性侵犯索赔作出可选择的仲裁。作为调查报告的一部分，谷歌将提供更多关于公司性骚扰调查和结果的细节。还将更新和扩大强制性性骚扰培训。" +
            "<br/>" +
            "<br/>" +
            "下面是原文：在谷歌，我们努力建立一个支持我们的员工并使他们能够做到最好的工作场所。作为CEO，我非常认真地承担起这个责任，并致力于做出我们需要改进的改变。在过去的几周里，谷歌的领导者们和我听到了你们的反馈，也被你们分享的故事感动了。" +
            "<br/>" +
            "我们认识到，过去我们并没有把所有事情都做好，对此我们深表歉意。很明显，我们需要做一些改变。" +
            "<br/>" +
            "今后，我们将在处理关切问题方面提供更多透明度。我们将更好地支持和照顾养育他们的人。我们将加倍致力于成为一个具有代表性、公平、尊重员工的工作场所。" +
            "<br/>" +
            "今天，我们宣布了一项取得进展的全面行动计划。详文如下，我鼓励每个人都读它。以下是一些关键的变化：" +
            "<br/>" +
            "我们将对个人性骚扰和性侵犯索赔作出可选择的仲裁。在仲裁过程中，谷歌从来没有要求保密，由于一些原因（例如个人隐私），仲裁仍可能是最佳途径，但我们承认，选择应该由您决定。" +
            "<br/>" +
            "作为我们调查报告的一部分，我们将提供更多关于公司性骚扰调查和结果的细节。" +
            "<br/>" +
            "我们正在以三种方式改进我们处理和调查您关注的问题的方式：我们正在调整我们的报告途径，将它们整合到一个专门的站点上，并包括现场支持。我们将加强处理问题的流程——包括谷歌员工有一个支持人员陪同的权利。在此过程中，我们将为谷歌员工提供额外的照顾和资源。这包括长期咨询和职业支持，" +
            "<br/>" +
            "我们将更新和扩大我们的强制性性骚扰培训。" +
            "<br/>" +
            "我们将再次承诺在2019年，在全公司范围内推行多元化、公平性和包容性的OKR，重点通过招聘、晋升和留任来提高代表性，并为每个人创造更包容的文化。我们的首席多样性官将继续每月向我和我的领导团队提供最新进展。" +
            "<br/>" +
            "我希望你能花时间阅读我们今天宣布的所有行动。" +
            "<br/>" +
            "谢谢大家给我们的反馈。在这一领域，我们需要不断取得进展，并致力于这样做。我们经常听到谷歌人说在这里工作最好的一点在于这里有其他谷歌人。即使在困难时期，我们也被同事们创造更好工作环境的承诺所鼓舞。在过去的几周里，这一点得到了很好的体现。",
        },
        {
          t: "大河奔流二十载腾讯再越分水岭",
          f: "谷歌员工罢工新进展: CEO发信回应大规模抗议",
          b: "本地生活服务首战天猫双11",
          img: require("../../../assets/img/qydt/qydt7.jpeg"),
          c:
            "摘要：互联网已经走到舞台的中央，新经济已经是国家重器，正视自己基础设施的地位，承担大国重器的责任，惟其如此，才能大道直行，惟其如此，才能无远弗届。" +
            "<br/>" +
            "<br/>" +
            "1、春潮缘起 " +
            "<br/>" +
            "1998年是中国互联网的一个分水岭。 " +
            "<br/>" +
            "在这一年，中国最早最知名的互联网企业瀛海威15人管理层出走，号称流星雨事件，张树新等老一代互联网人退出历史舞台； " +
            "<br/>" +
            "张朝阳通过一系列造势搜狐爆得大名，丁磊转型门户网易初现雏形，王志东拿到投资，三大门户竞争模式即将拉开序幕； " +
            "<br/>" +
            "同样是这一年，深圳国企职工马化腾放弃了铁饭碗，开始创办企业，马云决定年底前离开令人伤心的北京，带着老部下回杭州继续征战。 " +
            "<br/>" +
            "二十年后，当年的数字英雄有些已经陨落，有些萌生退意，而后发先至的腾讯和阿里巴巴，都迎来了生命中的高光时刻。 " +
            "<br/>" +
            "中国互联网肇始于1995年，真正打下基础，迎来第一次高潮，就是在改革开放20周年的1998年。 " +
            "<br/>" +
            "深圳，这座改革开放孕育的城市，1992年历史转折中《东方风来满眼春》发源地，在1998年迎来了一个世界级企业的诞生。 " +
            "<br/>" +
            "说来奇怪，中国两家能够抗衡美国互联网，超过3000亿美元市值的互联网公司，一家在深圳，一家在杭州。 " +
            "<br/>" +
            "催生它们的源泉，来自北京。 " +
            "<br/>" +
            "1998年3月，新一届政府成立，新总理的第一项任务，就是政府机构改革。 " +
            "<br/>" +
            "中央政府机构改革方案把40个部委精简为29个，政府机关的人数分流一半。调整和减少专业经济部门，加强宏观调控和执法监管部门。 " +
            "<br/>" +
            "在邮电部和电子工业部的基础上组建信息产业部。广播电影电视部、航天工业总公司、航空工业总公司的信息和网络管理的政府职能，并入信息产业部。 " +
            "<br/>" +
            "这是中国互联网产业起飞的关键一招，管理网络的婆婆减少，民间迸发出无穷活力。 " +
            "<br/>" +
            "2、春水拍山 " +
            "<br/>" +
            "中国的科技力量，都隐藏在三山五岭之间，一旦时机成熟，就如春水拍山，滔滔东流。 " +
            "<br/>" +
            "腾讯大河奔流20载，如今形成了几大中国第一的业务，社交、游戏、应用宝、手机浏览器、门户网站、视频网站、手机管家。 " +
            "<br/>" +
            "腾讯的成功，是改革红利叠加技术进步，也是个人奋斗融入历史进程。 " +
            "<br/>" +
            "老子云，上善若水，水利万物而不争，处众人之所恶，故几于道。 " +
            "<br/>" +
            "腾讯的起家，尤其是2010年之后的发展，更是润物无声，藏富于友。 " +
            "<br/>" +
            "全国工商联公布改革开放40年“百名杰出民营企业家”，马化腾榜上有名。 " +
            "<br/>" +
            "马化腾能获此殊荣，跟腾讯创业经历有关，也与深圳这座城市有关。 " +
            "<br/>" +
            "2002年11月，一名自称“我为伊狂”的网民，在人民网“强国论坛”发表了题为《深圳，你被谁抛弃》的文章，这篇文章在全国引起极大反响，引来了时任深圳市市长的关注，政府和市民都进行了全面的反思。 " +
            "<br/>" +
            "文章认为，深圳高新技术产业停滞不前，中国硅谷目标太过遥远。 " +
            "<br/>" +
            "2018年，深圳不但拥有了华为和中兴，还有了市值超过3000亿美元的腾讯。 " +
            "<br/>" +
            "在“我为伊狂”为深圳叹息的时候，腾讯正在筚路蓝缕的创业期，一个明日之星正在冉冉升起。 " +
            "<br/>" +
            "穿越2000年互联网泡沫，腾讯成为深圳二次创业的典型代表，消费互联网的顶尖代表，在社交和数字内容领域执牛耳者。 " +
            "<br/>" +
            "1998年7月，中国互联网络信息中心(CNNIC)发布到6月30日我国上网人数已近117.5万，其中直接上网用户约为32.5万人，拨号上网约为85万人；上网计算机为54.2万台，其中直接上网计算机为8.2万台。 " +
            "<br/>" +
            "2018年，腾讯微信和WeChat合并月活用户超过10.6亿，20年里增长100倍。 " +
            "<br/>" +
            "浩浩荡荡的用户长征，是腾讯的基本盘。腾讯的用户，基本就是整个中国的互联网用户。 " +
            "<br/>" +
            "一部中国互联网25年史，腾讯是浓墨重彩的一笔，从门户时代、社交网络到移动互联网，在每个阶段腾讯都有出彩的表现。 " +
            "<br/>" +
            "如果说1978年是改革开放的起点，1992年二次改革的高潮部分就是98年动真格，腾讯就是二次改革的成果。 " +
            "<br/>" +
            "3、无为而治 " +
            "<br/>" +
            "深圳诞生腾讯，腾讯能成为香港恒生第一股，都是源自于“无为而治”。 " +
            "<br/>" +
            "什么叫做无为而治？ " +
            "<br/>" +
            "道德经曰：“我无为，而民自化；我好静，而民自正；我无事，而民自富；我无欲，而民自朴”。 " +
            "<br/>" +
            "无为乃是经过有为的思考，以时势、趋势的判断做出顺势而为的行为，顺应自然的变化规律，使事物保持天然的本性而不是人为做作，从而达到“无为而无不为”的境界。 " +
            "<br/>" +
            "无为的目的不是思退，而是达到有为。 " +
            "<br/>" +
            "金庸先生在与总设计师曾经有一段非常有名的对话。 " +
            "<br/>" +
            "金庸说：“中国的传统政治哲学，是盼望国家领导人'清静无为'。共产党人当然不能'无为'，要'有为'，但领导人心境清静一些，动作清静一些，还是好的。” " +
            "<br/>" +
            "总设计师说：“担任领导的人，不能出太多的主意。如果考虑没有成熟，不断有新的主意出来，往往要全国大乱。政治家主意太多是要坏事的。领导人宁静和平，对国家有好处，对人民有好处。” " +
            "<br/>" +
            "十八届三中全会提出，使市场在资源配置中起决定性作用。 " +
            "<br/>" +
            "政府对具体经济活动不干涉，交给市场，深圳民营经济发展迅速，市场活力全国领先。 " +
            "<br/>" +
            "腾讯20年的发展，也有无为而治的影子。 " +
            "<br/>" +
            "腾讯的赛马机制，微信跑赢了移动QQ，张小龙从新生代干将中一跃而出。 " +
            "<br/>" +
            "业界公认，马化腾对公司管理比较宽松，腾讯架构调整大约7年一次，管理团队基本上是“不折腾”。 " +
            "<br/>" +
            "腾讯员工的稳定性，在互联网公司是罕见的，这当然会造成一些惰性，但稳定预期对公司长远的发展利远大于弊。 " +
            "<br/>" +
            "对大国而言，无为而治是放水养鱼；对企业而言，无为而治则良将如潮。 " +
            "<br/>" +
            "Facebook收购Instagram和WhatsApp之后，创始人都先后离职，留住人才对谁都是一个难题。 " +
            "<br/>" +
            "张小龙研发掌控微信8年，他定的规则依然是微信的铁律；QQ的产品经理任宇昕成长为公司肱骨之臣，仍然活跃在第一线。 " +
            "<br/>" +
            "风云激荡20年，腾讯以“无为而治”成了大赢家。 " +
            "<br/>" +
            "4、探索边界 " +
            "<br/>" +
            "在CCTV《对话》栏目的最新采访中，腾讯CEO马化腾念了一封短信《致未来》。 " +
            "<br/>" +
            "信中说，中国互联网因改革开放应运而生，互联网在中国的发展，最应该感谢改革开放给予的巨大包容和创新空间。 " +
            "<br/>" +
            "腾讯受益于改革，也助力改革。腾讯积极响应数字中国战略，致力成为各行各业的数字化助手。 " +
            "<br/>" +
            "站在改革开放四十年的关口，腾讯的确应该有气魄，有格局，来谋划更大的事业。 " +
            "<br/>" +
            "毕竟，20年发展的腾讯，既是改革开放的理由，也是改革开放的成果，更是下一步深化改革的地基。 " +
            "<br/>" +
            "腾讯作为中国互联网的四梁八柱，已经是重要的信息化基础设施。 " +
            "<br/>" +
            "改革开放进入了新阶段，中国政治生态、国际环境都发生了深刻的变化。 " +
            "<br/>" +
            "人心似水，民动如烟。腾讯所处的政治经济乃至舆论圈层，都不似过去那般简单。 " +
            "<br/>" +
            "一方面，腾讯的社交、游戏和媒体业务，必然要思危、思变； " +
            "<br/>" +
            "另一方面，微信支付飞入寻常百姓家，IG战队获胜年轻人狂欢，时间或许在腾讯这边。 " +
            "<br/>" +
            "调整架构，改变方向，守正出奇，有所作为，是腾讯面向未来作出的选择。 " +
            "<br/>" +
            "科学技术是第一生产力，腾讯得益于技术进步，也回馈了科学普及。 " +
            "<br/>" +
            "被业界称为最烧脑的腾讯WE大会，就是腾讯探索科技前沿，致力科普的重要抓手。 " +
            "<br/>" +
            "一大批世界顶级科学家霍金、基恩·索普等都登上了这个舞台答疑解惑。 " +
            "<br/>" +
            "下一个20年，腾讯更重要的工作，是把互联网能力赋能传统行业，用消费互联网带来的成果去改造和升级产业互联网。 " +
            "<br/>" +
            "在最近的腾讯全球合作伙伴大会上，马化腾公开信表示，移动互联网的主战场，正在从上半场的消费互联网，向下半场的产业互联网方向发展。 " +
            "<br/>" +
            "腾讯未来的任务是做“连接器”、“工具箱”、“生态共建者”，帮助实体产业在各自的赛道上成长出更多的世界冠军。 " +
            "<br/>" +
            "从腾讯的历史看，它的承诺都兑现了，开放生态的同时也获得了自身的发展；未来产业互联网如果能如预期一样发展，腾讯也将从中获益。 " +
            "<br/>" +
            "下一个20年的腾讯，应该是随着中国经济脉搏跳动的腾讯，它不但是中国的互联网基础设施，也应该是世界的互联网基础设施，随着一带一路扩展，进入更广阔的市场。 " +
            "<br/>" +
            "腾讯在东南亚、印度和欧洲都有不错的开局，电商、媒体、社交和本地生活服务都有深度介入。 " +
            "<br/>" +
            "拥有庞大体量的腾讯，也应该在国际技术组织中拥有更多的话语权，中国互联网产业的声音，值得被世界认真倾听。 " +
            "<br/>" +
            "2018年，四十年改革开放将更加深化，“中国对外开放的大门不会关闭，只会越开越大”；二十年艰苦创业的腾讯将由内及外，打开自己生态，赋能传统行业，成为腾讯再出发的分水岭。" +
            "<br/>" +
            "经济发展是中国最大的政治，一带一路是中国未来的机会。 " +
            "<br/>" +
            "数字化程度每提高10%，人均GDP增长0.5%至0.62%，赋能传统行业，互联网巨头们可以获得巨大的经济利益，也能深深嵌入庞大经济体，不再游离于主流之外。 " +
            "<br/>" +
            "互联网国际化，不光输出资本，在国际商业竞争硬碰硬上，也输出了中国模式、中国思维。 " +
            "<br/>" +
            "互联网已经走到舞台的中央，新经济已经是国家重器，正视自己基础设施的地位，承担大国重器的责任，惟其如此，才能大道直行，惟其如此，才能无远弗届。 ",
        },
        {
          t: "本地生活服务首战天猫双11",
          f: "大河奔流二十载腾讯再越分水岭",
          b: "无",
          img: require("../../../assets/img/qydt/qydt8.jpeg"),
          c:
            "2018天猫双11，因为本地生活服务的加入，第一次真正成为涵盖“衣食住行吃喝玩乐”全场景的消费狂欢。" +
            "<br/>" +
            "毫无疑问，这又将成为一个新商业的历史节点：在天猫双11的第十年、饿了么的第十年、口碑的第三年，本地生活服务与天猫双11完成汇流。" +
            "<br/>" +
            "阿里本地生活服务公司联动线下百万商户首战天猫双11 " +
            "<br/>" +
            "创头条获悉，狂欢已经开始：在饿了么，过去十天，包括星巴克、麦当劳、西贝等在内的9大餐饮品牌在其与饿了么合作的“超级品牌日”期间初战告捷。" +
            "<br/>" +
            "约1万家门店的订单量同比增长近200%；家乐福第一天成交比平日增长六倍；百果园单日在饿了么APP内搜索量就接近百万次。而在口碑，从11月1日开始，每天有几千万用户在口碑上收藏、加购各类吃喝玩乐套餐，海底捞的火锅底料在两天内被收藏10万次，肯德基的藤椒嫩笋鸡腿卷买一送一兑换券售出20万份。 " +
            "<br/>" +
            "蜂鸟配送成为天猫双11最新即时配送力量 " +
            "<br/>" +
            "本地生活服务全面加入天猫双11，是阿里巴巴商业操作系统赋能品牌商家走向数字化时代所开辟的又一块全新版图。重新定义城市生活，吃喝玩乐全场景狂欢就此全面开启。" +
            "<br/>" +
            "过去半年来，饿了么快速融入阿里商业生态体，与口碑一起合力并进。饿了么与口碑平台上的广大商户，第一次从看客变身成为天猫双11的主人：过去的几天里，海底捞10月27日推出原价419元现价210元的“欢享钜惠火锅趴套餐”等优惠，单日销售额同比增长超90%；西贝莜面村、真功夫、五芳斋、小南国等推出1元菜以及半价套餐活动，销售额同比增长近60%。" +
            "<br/>" +
            "在天猫双11的第十个年头，从最初的电商，到物流、金融、云计算，天猫双11的内核外延不断丰富，而今整个阿里商业生态体首次全面集结，本地生活服务参与成为最大亮点之一：天猫上18万全球品牌、20万家天猫新零售智慧门店，遍布165个城市的近100家盒马鲜生门店、470余家超市，银泰百货在34座城市的62家门店，还有饿了么和口碑联动100万餐饮、水果、鲜花、娱乐的本地生活服务商家，300万蜂鸟骑手全数参与。" +
            "<br/>" +
            "商业集结、生态协同、数字力量驱动变革，将为本地生活服务平台上的消费者和品牌商户带来长远的价值。" +
            "<br/>" +
            "2018年的天猫双11，饿了么旗舰店、口碑生活旗舰店在天猫平台正式亮相，星巴克、海底捞、肯德基等全球400个知名品牌首次集中在线上推出电子消费券，线下推出半价、满减等活动，第一次成建制地加入线上线下狂欢，给用户带来全方位选择。" +
            "<br/>" +
            "天猫双11，星巴克首次参与饿了么“超级品牌日”活动，单日销量增长近4倍，在天猫饿了么旗舰店中1元冷萃咖啡券已经被205495人加入购物车。天猫双11当日，星巴克在多个城市的多家门店24小时不打烊，“专星送”服务也在该时间段正常运营，11月11日零点还会开启限时秒杀。" +
            "<br/>" +
            "在本地生活服务的第十个年头，饿了么、口碑也从最初一个简单的点餐送餐平台，发展成今天融合了吃喝玩乐多种生活场景的本地生活服务平台。" +
            "<br/>" +
            "今天，本地生活服务将与阿里生态内的其他商业实体形成全方位的全球共振，对工作场景、休闲场景和居家场景形成全范围覆盖。作为一名商户，可以在天猫国际运营销售，可以通过蚂蚁获取金融支持，可以享受饿了么和口碑提供的包括营销、订单、支付、配送在内的一整套线上线下服务方案；而作为一名消费者，可以在优酷看视频，通过淘票票买票上影院，可以通过口碑找到心仪的商家下单点餐，可以在饿了么动动手指，30分钟内，一杯原汁原味的星巴克咖啡就已经在手边。" +
            "<br/>" +
            "每年天猫双11的峰值都会成为来年的常态，对阿里本地生活服务来说同样如此。高频、刚需的使用场景，加上数字驱动的优质消费体验，让阿里本地生活服务公司作为先锋，重新定义城市生活。 " +
            "<br/>" +
            "2018年，天猫双11和以口碑、饿了么为代表的本地生活服务平台终于相逢，双方都努力在让彼此变得更好。" +
            "<br/>" +
            "蜂鸟已经成为350万活跃商户的“最后一公里”配送的核心力量，300万蜂鸟骑手，平均配送28分钟，7*24小时服务着全国676个城市和一千多个县。作为阿里新零售的基础设施，蜂鸟 “入职”7个月成绩优异，以新零售为例，蜂鸟骑手一方面为全国上千家商超提供配送服务，另一方面巨大的订单量，也反向推动着这些商超进行新零售路径下的全新升级。" +
            "<br/>" +
            "网商银行联合50家金融机构备下2000亿贷款资金，在天猫双11期间为本地生活服务平台上的中小商户提供完善的金融服务。《阿里本地生活服务中小商户报告》显示，从一线到四线城市，92%的夜宵商户和超过80%的女性商户积极参与、踊跃备战。" +
            "<br/>" +
            "他们会是楼下的一家蛋糕店、一家鲜花店、一家便利店甚至是一家烧烤店。一切正如阿里巴巴CEO张勇所言，商业会重新定义，生活会被重新定义。",
        },
      ],
      qydtIndex: 9,
    };
  },
  created() {
    console.log(this.$route.params.title);
    //第二页
    //第二页第一个
    console.log("业务应用场景受限，商汤科技能否把握发展机会?");
    console.log("Al旗手李彦宏和他的新百度");
    if (
      this.$route.params.title ==
      "苹果有望在2年内完成Mac自家芯片供给，这三种情况最有可能发生"
    ) {
      this.qydtIndex = 4;
    }
    //第二页第二个
    if (
      this.$route.params.title == "谷歌员工罢工新进展: CEO发信回应大规模抗议"
    ) {
      this.qydtIndex = 5;
    }
    //第二页第三个

    if (this.$route.params.title == "大河奔流二十载腾讯再越分水岭") {
      this.qydtIndex = 6;
    }
    //第二页第四个

    if (this.$route.params.title == "本地生活服务首战天猫双11") {
      this.qydtIndex = 7;
    }
    //第一页
    //第一页第一个
    if (this.$route.params.title == "Al旗手李彦宏和他的新百度") {
      console.log("jin;ll ");
      this.qydtIndex = 0;
    }
    //第一页第二个

    if (
      this.$route.params.title == "业务应用场景受限，商汤科技能否把握发展机会?"
    ) {
      this.qydtIndex = 1;
    }
    //第一页第三个

    if (
      this.$route.params.title ==
      "五国议会联合要求扎克伯格出席听证会 但全遭拒绝"
    ) {
      this.qydtIndex = 2;
    }
    //第一页第四个

    if (this.$route.params.title == "天猫双11十年如何下一个开始") {
      this.qydtIndex = 3;
    }
    this.$forceUpdate();
    if (!this.$route.params.title) {
      this.list = this.$store.state.list;
    }

    if (this.qydtIndex == 9) {
      this.qydtIndex = this.$store.state.qydtIndex;
    }
    // this.$store.commit("setlist", this.list);
    this.$store.commit("setqydtindex", this.qydtIndex);
  },
  methods: {
    frontClick() {
      if (this.qydtIndex <= 0) {
        return;
      }
      this.qydtIndex--;
      this.$store.commit("setqydtindex", this.qydtIndex);
    },
    backClick() {
      if (this.qydtIndex >= 7) {
        return;
      }
      this.qydtIndex++;
      this.$store.commit("setqydtindex", this.qydtIndex);
    },
  },
};
</script>

<style scoped>
.home {
  text-align: center;
}
.tj div:hover {
  color: lightskyblue;
  cursor: pointer;
}
</style>