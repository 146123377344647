import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import axios from "axios";
import 'element-ui/lib/theme-chalk/index.css';

// 导入格式化样式文件
import "reset-css";

// 导入共有样式
import "./assets/css/public.css";
Vue.prototype.$axios = axios
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
