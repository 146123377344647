import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    list:JSON.parse(sessionStorage.getItem("list")),
    index:sessionStorage.getItem("index"),
    qydtIndex:sessionStorage.getItem("qydtIndex"),
    info:JSON.parse(sessionStorage.getItem("info")),
  },
  getters: {},
  mutations: {
    setlist(state, list) {
			state.list = list
      sessionStorage.setItem("list",JSON.stringify(state.list))
		},
    setinfo(state, info) {
			state.info = info
      sessionStorage.setItem("info",JSON.stringify(state.info))
		},
    setindex(state, index) {
			state.index = index
      sessionStorage.setItem("index",state.index)
		},
    setqydtindex(state, qydtIndex) {
			state.qydtIndex = qydtIndex
      sessionStorage.setItem("qydtIndex",state.qydtIndex)
		},
  },
  actions: {},
  modules: {
   
   
  },
});
