import Vue from "vue";
import VueRouter from "vue-router";
import fwdtDetail from "../views/childrenComps/fwdt/fwdtDetail.vue"
import qydtDetail from "../views/childrenComps/qydt/qydtDetail.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/gywm",
    name: "gywm",
    component: () => import("../views/gywm.vue"),
  },
  {
    path: "/jjfn",
    name: "jjfn",
    component: () => import("../views/jjfn.vue"),
   
  },
  {
    path: "/retailDetail",
    name: "retailDetail",
    component: () => import("../views/childrenComps/jjfn/retailDetail.vue"),
  },
  {
    path: "/terminalDetail",
    name: "terminalDetail",
    component: () => import("../views/childrenComps/jjfn/terminalDetail.vue"),
  },
  {
    path: "/InternetDetail",
    name: "InternetDetail",
    component: () => import("../views/childrenComps/jjfn/InternetDetail.vue"),
  },
  {
    path: "/screenDetail",
    name: "screenDetail",
    component: () => import("../views/childrenComps/jjfn/screenDetail.vue"),
  },
   
  {
    path: "/fwdt",
    name: "fwdt",
    component: () => import("../views/fwdt.vue"),
    
  },
  {
    path: "/oneDetail",
    name: "oneDetail",
    component: () => import("../views/childrenComps/qydt/oneDetail.vue"),
  },
  {
    path: "/TwoDetail",
    name: "TwoDetail",
    component: () => import("../views/childrenComps/qydt/TwoDetail.vue"),
  },
  {
    path: "/TheDetail",
    name: "TheDetail",
    component: () => import("../views/childrenComps/qydt/TheDetail.vue"),
  },
  {
    path: "/ForDetail",
    name: "ForDetail",
    component: () => import("../views/childrenComps/qydt/ForDetail.vue"),
  },
  {
    path: "/fwdtDetail",
    name: "fwdtDetail",
    component: fwdtDetail,
    prop:true
    
  },
  {
    path: "/qydt",
    name: "qydt",
    component: () => import("../views/qydt.vue"),
  },
  {
    path: "/qydtDetail",
    name: "qydtDetail",
    component: qydtDetail,
  },
   {
    path: "/lxwm",
    name: "lxwm",
    component: () => import("../views/lxwm.vue"),
  },
  {
    // path: "/details/:id",
    path: "/details",
    name: "details",
    component: () => import("../views/Details.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});



//解决重复点击路由  连续触发 push  方法的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
